<template>
  <section class="typeUser">
    <h2 class="typeUser__title">Tipo de usuario *</h2>
    <p>Seleccione a continuación el usuario que desea crear</p>
    <div class="typeUser__contentRoles">
      <li class="typeUser__checkbox" v-for="role in rolesOptions" :key="role.key">
        <input class="mycheck " type="radio" name="role" :id="role.key" :value="role.key" v-model="$store.state.user.dataUser.role" />
        <label :for="role.key">{{ role.name }}</label>
      </li>
    </div>
  </section>
</template>

<script>
export default {
  data() {
    return {
      roles: this.$global.dictionary.roles_es_auto,
      rolesOptions: [],
    };
  },
  mounted() {
    this.rolesOptions = Object.entries(this.roles).map(([key, name]) => ({ name, key }));
  },
};
</script>

<style lang="scss">
.typeUser {
  @include Flex(column, flex-start, flex-start);
  width: 100%;
  gap: $mpadding;
  &__title {
    font-weight: lighter;
    font-size: 24px;
  }
  &__contentRoles {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(130px, 1fr));
    width: 100%;
    gap: $mpadding / 2;
  }
  &__checkbox {
    @include Flex(row, flex-start);
    width: 100%;
    gap: $mpadding / 2;
    > label {
      cursor: pointer;
      text-transform: capitalize;
      user-select: none;
    }
  }
  .mycheck {
    width: 16px;
    height: 16px;
    border-radius: 50%;
    &:checked {
      background-color: $primary-color;
      border-color: $primary-color;
    }
  }
}
</style>
